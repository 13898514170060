<template>
  <router-view></router-view>
</template>
<script setup>
console.log("农商慧采系统： https//:www.qingwuit.com  QQ群：1062159788");
</script>
<style>
img {
  max-width: 100%;
}
</style>
